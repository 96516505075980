import Api from './Api';
import { organisedPriceData, priceData } from '../interfaces/PriceData';

export let cheapestPrice = 0;

const GetPrices: (onlyCheapest?:boolean) => Promise<any> = async (onlyCheapest?:boolean) => {
  let organizedPrices: organisedPriceData = {};
  await Api('shipments/services').then((res) => {
    res['data'].map((row: priceData) => {
      row.size = row.size.toLowerCase();

      if (!(row.size in organizedPrices)) {
        organizedPrices[row.size] = {};
      }
      organizedPrices[row.size][row.provider] = row;

      if (!cheapestPrice || row.price < cheapestPrice) {
        cheapestPrice = row.price;
      }

      return row;
    });
  });
  return onlyCheapest ? cheapestPrice : organizedPrices;
}
export default GetPrices;