import React, { ReactElement } from 'react';
import OstaHeader from './OstaHeader';
import Header from './Header';
import Footer from './Footer';
import MainMenu from './MainMenu';

const Layout = ({ mainPage = '', children }: { mainPage?: string, children: ReactElement }) => {
  return (<>
    <OstaHeader />
    <Header />
    <div id={'page'}>
      {mainPage === 'sent' ? null : <MainMenu home={mainPage === 'send'} />}
      {children}
      {mainPage === 'sent' ? <MainMenu /> : null}
    </div>
    <Footer />
  </>);
};
export default Layout;
