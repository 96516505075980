import React, { useEffect, useState } from 'react';
import './scss/AutoComplete.scss';
import { AutoCompleteOption } from '../interfaces/AutoCompleteOption';
import Api from '../helpers/Api';
import IsMobile from '../helpers/IsMobile';

const AutoComplete = ({ label, options = [], dataUrl, callback }: {
  label: string, options?: AutoCompleteOption[], dataUrl?: string, callback?: any
}) => {
  const [prevValue, setPrevValue] = useState('');
  const [currValue, setCurrValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<AutoCompleteOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<AutoCompleteOption>();

  const ac = document.getElementById('autocomplete');
  const isMobile = IsMobile();

  useEffect(() => {
    callback(selectedOption?.value, selectedOption?.text);
  }, [callback, selectedOption]);

  useEffect(() => {
    setSelectedOption(undefined);
  }, [options]);


  const getCurrentIndex = (resetCurrent = false) => {
    let currentIndex: number = -1;
    for (let i in filteredOptions) {
      if (filteredOptions[i].active) {
        if (resetCurrent) {
          filteredOptions[i].active = false;
        }
        currentIndex = parseInt(i);
        break;
      }
    }
    return currentIndex;
  };


  const keyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    const newOptions: AutoCompleteOption[] = [];

    if (prevValue === val || selectedOption) {
      return;
    }
    if (dataUrl && val.length < 2) {
      setPrevValue('');
      setFilteredOptions([]);
      return;
    }

    if (dataUrl) {
      Api(dataUrl + val).then((res) => {
        const newOptions: AutoCompleteOption[] = [];
        if (res['box_locations']) {
          for (let option of res['box_locations']) {
            newOptions.push(option);
          }
        }
        if (newOptions.toString() !== options.toString()) {
          setFilteredOptions(newOptions);
        }
      });
    } else if (options) {
      for (let option of options) {
        let regex = new RegExp(val, 'i');
        if (option.text.search(regex) > -1) {
          newOptions.push(option);
        }
      }
      setFilteredOptions(newOptions);
    }
    setPrevValue(val);
  };
  const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let currentIndex = getCurrentIndex(true);

    if (['Enter', 'NumpadEnter', 'Escape'].includes(event.code)) {
      event.preventDefault();
      setFilteredOptions([]);
      if (currentIndex >= 0) {
        setSelectedOption(filteredOptions[currentIndex]);
        setPrevValue('');
      }
      return;
    }

    if (!options.length && !dataUrl) {
      return;
    }

    if (['ArrowDown', 'PageDown'].includes(event.code)) {
      event.preventDefault();
      if (selectedOption && !filteredOptions.length) {
        setFilteredOptions(options);
        return;
      }
      currentIndex += event.code === 'PageDown' ? 5 : 1;
    } else if (['ArrowUp', 'PageUp'].includes(event.code)) {
      event.preventDefault();
      currentIndex -= event.code === 'PageUp' ? 5 : 1;
    } else {
      setSelectedOption(undefined);
      return;
    }

    if (currentIndex < -1) {
      currentIndex = filteredOptions.length - 1;
    } else if (currentIndex > filteredOptions.length - 1) {
      currentIndex = -1;
    }

    if (currentIndex >= 0) {
      filteredOptions[currentIndex].active = true;
    }
    setFilteredOptions([...filteredOptions]);

    setTimeout(() => {
      const activeOption = document.querySelector('li.active');
      if (activeOption) {
        activeOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    });
  };
  const blur = () => {
    setFilteredOptions([]);
  };

  const showOptions = () => {
    setFilteredOptions(options);
  };

  const focus = () => {
    showOptions();
    if (isMobile && ac) {
      ac.scrollIntoView({ block: 'start' });
    }
  };

  let group: string = '';

  return (<>
    <input onClick={showOptions}
           onFocus={focus}
           onKeyUp={keyUp}
           onKeyDown={keyDown}
           onBlur={blur}
           onChange={(e) => {
             setCurrValue(e.currentTarget.value);
           }}
           value={selectedOption?.text ? selectedOption.text : currValue}
           placeholder={' '}
           id={'autocomplete'}
    />
    <span>{label}</span>
    {filteredOptions && filteredOptions.length ? <div className={'autocomplete'}>
      <ul>
        {filteredOptions && filteredOptions.length && filteredOptions.map((option, index) => {
          let regex = new RegExp(prevValue, 'i');
          let replaceStart = option.text.search(regex);
          let showText = option.text.replace(regex, '<strong>' + option.text.substring(replaceStart, replaceStart + prevValue.length) + '</strong>');
          if (option.extraText) {
            showText += ' <small>' + option.extraText + '</small>';
          }

          let optionGroup = null;
          if (option.group && option.group !== group) {
            optionGroup = <li className={'group-title'}>{option.group}</li>;
            group = option.group;
          }

          return (<React.Fragment key={index}>
              {optionGroup}
              <li
                className={option.active ? 'active' : ''}
                data-group={option.group}
                onMouseOver={() => {
                  getCurrentIndex(true);
                  filteredOptions[index].active = true;
                  setFilteredOptions([...filteredOptions]);
                }}
                onMouseDown={() => {
                  setSelectedOption(option);
                }}
                dangerouslySetInnerHTML={{ __html: showText }}
              ></li>
            </React.Fragment>);
        })}
      </ul>
    </div> : null}
  </>);
};
export default AutoComplete;