import { useTranslation } from 'react-i18next';
import Pack from '../components/Pack';
import { useParams } from 'react-router-dom';
import Provider from './send/Provider';
import { useEffect, useState } from 'react';
import GetPrices from '../helpers/GetPrices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import PackInfo from '../components/PackInfo';
import IsMobile from '../helpers/IsMobile';


const Send = () => {
  const { t } = useTranslation('common');
  const { size, provider } = useParams();

  const [prices, setPrices] = useState(Object);
  const [videoModal, setVideoModal] = useState(false);

  const isMobile = IsMobile();

  useEffect(() => {
    let elem = document.getElementsByClassName('navbar');
    if (elem[0]) {
      elem[0].scrollIntoView({inline: 'nearest'});
    }

    GetPrices().then((res) => {
      if (res) {
        sessionStorage.setItem('packPrices', res);
        setPrices(res);
      }
    });
  }, []);

  return (<>
    <h3 className={'wide'}>
      {t('choose_package_size')}
      <button className={'link thin right ml--4 mv--3 text-right'} onClick={() => {
        setVideoModal(true);
      }}>
        <FontAwesomeIcon icon={faYoutube} className={'mr-2 m-large-icon large'} />
        {t('intro-video-link')}
      </button>
    </h3>

    <div className={'clear-fix'}></div>


    <div className={'flex-row mb-4'}>
      {Object.keys(prices).map((boxSize) => {
        return (<div className={'col'} key={boxSize}>
          <Pack size={boxSize} prices={prices[boxSize]} active={size === boxSize} key={'boxSize' + boxSize} />
        </div>);
      })}
    </div>

    {size && !isMobile ? <Provider size={size} prices={prices[size]} /> : null}

    {provider && size ? <PackInfo provider={provider} prices={prices} size={size} /> : null}

    <div className={'modal large' + (videoModal ? ' show' : '')}>
      <div className={'center'}>
        <h3>{t('see_how_it_works')}</h3>

        {videoModal ? (<iframe className={'video-player'}
                               src='https://www.youtube.com/embed/KcAoTSdw08Y?si=Yi3_5xAf-QmBUOyn'
                               title='YouTube video player'
                               allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                               allowFullScreen></iframe>) : null}

        <div className={'mt-6 mb-4'}>
          <button className={'link thin'} onClick={() => {
            setVideoModal(false);
          }}>{t('close')}</button>
        </div>
      </div>
    </div>
  </>);
};
export default Send;
