import { useParams } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from '../helpers/Url';
import Api from '../helpers/Api';
import FormatDate from '../helpers/FormatDate';

type FormErrors = {
  code?: string
}

interface packInfo {
  id: number,
  provider: 'itella' | 'omniva' | 'venipak',
  barcode: string,
  labelUrl: string,
  dateShipped?: string,
  dateReceived?: string,
}

const Follow = () => {
  const { t } = useTranslation('common');
  const { param2, param3 } = useParams();
  const code: string = param3 ? param3 : param2 ? param2 : '';
  const [packCode, setPackCode] = useState(code);
  const [packInfo, setPackInfo] = useState<packInfo[]>([]);

  useEffect(() => {
    if (!packCode) {
      return;
    }
    Api('shipments?barcode=' + packCode).then((res) => {
      setPackInfo(res['data']);
    });
  }, [packCode]);

  if (code !== packCode) {
    return <Redirect href={'/follow/' + packCode} />;
  }

  return (<>
    <h3>{t('package_tracking')}</h3>

    <Formik
      initialValues={{
        code: code
      }}
      validate={values => {
        const errors: FormErrors = {};

        if (!values.code) {
          errors.code = t('errors.required');
        } else if (values.code.length < 6) {
          errors.code = t('errors.invalid_pack_code');
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setPackCode(values.code);
        setSubmitting(false);
      }}
    >{({ isSubmitting }) => (<Form>
      <div className={'flex-row mobile-row'}>
        <label className={'col'}>
          <Field type={'text'} name={'code'} placeholder={' '} />
          <span>{t('package_tracking_code')}</span>
          <ErrorMessage name={'code'} component={'div'} className={'error'} />
        </label>

        <div className={'col-1-5'}>
          <button type={'submit'} className={'btn-primary wide'} disabled={isSubmitting}>{t('track_package')}</button>
        </div>
      </div>

    </Form>)}
    </Formik>

    <div className={'mv-8 center'}>
      {packCode ? (packInfo && packInfo.length > 0 ? (<ul className={'delivery'}>
        {packInfo.map((info, index) => {
          return (<li className={'status-' + (info.dateShipped ? '1' : (info.dateReceived ? '2' : '0'))} key={index}>
            {t('provider.' + info.provider)}
             | {t('status.' + (info.dateShipped ? 'shipped' : (info.dateReceived ? 'delivered' : 'open')))}
            <small>{info.dateShipped || info.dateReceived ? ' (' + FormatDate(info.dateReceived ? info.dateReceived : info.dateShipped) + ')' : null}</small>
          </li>);
        })}
      </ul>) : (<>
        {t('errors.package_with_code_not_found')}
      </>)) : null}
    </div>
  </>);
};
export default Follow;