import { useEffect, useState } from 'react';

const IsMobile = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth < 768);
  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export default IsMobile;