import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Url from '../helpers/Url';
import UseClickOutside from '../helpers/UseClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

const LanguageSelector = () => {
  const { t, i18n } = useTranslation('common');
  const [opened, setOpened] = useState(false);

  const ref = UseClickOutside(() => setOpened(false));

  const allLanguages = process.env.REACT_APP_LANGUAGES;
  if (!allLanguages) {
    return null;
  }
  let langs = allLanguages.split(',');

  let currentUrl = window.location.pathname.toString() + window.location.search.toString();
  if (langs.includes(currentUrl.split('/')[1])) {
    currentUrl = currentUrl.substring(3);
  }

  const changeLanguage = ({ lang }: { lang: string }) => {
    i18n.changeLanguage(lang).then(() => setOpened(false));
  };

  return (
    <div ref={ref}>
      <button onClick={() => setOpened(!opened)} className={'link'}>{i18next.language.toUpperCase()} <FontAwesomeIcon icon={faChevronDown} /></button>
      <span className={opened ? 'dropdown' : 'hide'}>
        {langs.map((lang) => (
          <Url href={currentUrl} lang={lang} key={lang} onClick={() => changeLanguage({ lang: lang })} className={i18next.language === lang ? 'hide' : ''}>
            {t('lang.' + lang)}
          </Url>
        ))}
      </span>
    </div>
  );
};

export default LanguageSelector;
