import { useParams } from 'react-router-dom';
import NotFound from './pages/NotFound';
import React, { lazy, Suspense } from 'react';
import Layout from './components/Layout';
import Send from './pages/send';
import Follow from './pages/follow';
import Map from './pages/map';

const UrlWrapper = () => {
  const { param1, param2, param3 } = useParams();
  let page = param1;
  let subPage = param2;

  const allLanguages = process.env.REACT_APP_LANGUAGES;
  let langs: string[] = [];
  if (allLanguages) {
    langs = allLanguages.split(',');
  }

  if (param1 && langs.includes(param1)) {
    subPage = param3;
    page = param2;
  }

  if (!page) {
    page = 'send';
  }

  let usePage = page;
  if (subPage) {
    usePage = page + '/' + subPage;
  }

  // predefined pages
  if (usePage === 'send') {
    return <Layout mainPage={page}><Send /></Layout>;
  } else if (usePage === 'send') {
    return <Layout mainPage={page}><Follow /></Layout>;
  } else if (usePage === 'map') {
    return <Layout mainPage={page}><Map /></Layout>;
  }

  const LoadedPage = lazy(() =>
    import('./pages/' + usePage).catch(() => ({
      default: () => {
        if (subPage) {
          const LoadedMainPage = lazy(() =>
            import('./pages/' + page).catch(() => ({
              default: () => <NotFound message={usePage} />,
            })),
          );
          return (
            <Suspense fallback={null}>
              <LoadedMainPage />
            </Suspense>
          );
        } else {
          return <NotFound message={usePage + ' | ' + subPage} />;
        }
      },
    })),
  );

  return (
    <Layout mainPage={page}>
      <Suspense fallback={null}>
        <LoadedPage />
      </Suspense>
    </Layout>
  );
};

export default UrlWrapper;
