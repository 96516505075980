import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';
import './scss/index.scss';
import UrlWrapper from './UrlWrapper';
import Send from './pages/send';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<UrlWrapper />}>
          <Route index element={<Send />} />

          {/*predefined routes for faster route detection*/}
          <Route path={'send'} element={<Send />} />
          <Route path={'send/:size'} element={<Send />} />
          <Route path={'send/:size/:provider'} element={<Send />} />

          <Route path={':param1/send'} element={<Send />} />
          <Route path={':param1/send/:size'} element={<Send />} />
          <Route path={':param1/send/:size/:provider'} element={<Send />} />

          <Route path={':param1/:param2/:param3'} element={<UrlWrapper />} />
          <Route path={':param1/:param2'} element={<UrlWrapper />} />
          <Route path={':param1'} element={<UrlWrapper />} />
          <Route path={'*'} element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;

