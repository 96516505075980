import { LinkProps, Navigate, NavLink } from 'react-router-dom';
import React from 'react';
import i18next from 'i18next';
import { NavigateProps } from 'react-router/dist/lib/components';

interface UrlProps {
  children: string | React.ReactElement;
  href: LinkProps['to'];
  lang?: string;
  linkProps?: Omit<LinkProps, 'to'>;
}

const Url = ({
  children,
  href,
  lang,
  ...linkProps
}: Omit<LinkProps, 'to'> & UrlProps) => {
  const allLanguages = process.env.REACT_APP_LANGUAGES;
  let langs: string[] = [];
  if (allLanguages) {
    langs = allLanguages.split(',');
  }

  if (href.toString().substring(0, 4) !== 'http') {
    if (lang) {
      if (lang !== langs[0]) {
        href = '/' + lang + href;
      }
    } else if (i18next.language !== langs[0]) {
      href = '/' + i18next.language + href;
    }
  }

  return (
    <NavLink to={href} {...linkProps}>
      {children}
    </NavLink>
  );
};
export default Url;



export const Redirect = ({href = '', ...NavigateProps}: Omit<NavigateProps, 'to'> & {href: string}) => {
  let langs: string[] = [];
  const allLanguages = process.env.REACT_APP_LANGUAGES;
  if (allLanguages) {
    langs = allLanguages.split(',');
  }

  if (href.toString().substring(0, 4) !== 'http') {
    if (i18next.language !== langs[0]) {
      href = '/' + i18next.language + href;
    }
  }

  return <Navigate to={href} {...NavigateProps} />;
}
