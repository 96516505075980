import './scss/Header.scss';
import { useTranslation } from 'react-i18next';
import logoVenipak from '../img/logos/venipak.svg';
import logoOmniva from '../img/logos/omniva.svg';
import logoSmartpost from '../img/logos/smartpost.svg';
import boxes from '../img/boxes.png';

const Header = () => {
  const { t } = useTranslation('common');

  return (<header className={'header'}>
    <div className={'container'}>
      <div className={'slogan'}>
        {t('header.slogan')}
        <div className={'logos'}>
          <img src={logoOmniva} alt={'Omniva'} className={'omniva'} />
          <img src={logoSmartpost} alt={'Itella smartpost'} className={'smartpost'} />
          <img src={logoVenipak} alt={'Venipak'} className={'venipak'} />
        </div>
      </div>
      <div className={'boxes'}>
        <img src={boxes} alt={''} />
      </div>
    </div>
  </header>);
};
export default Header;