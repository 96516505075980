import './scss/Footer.scss';
import { useTranslation } from 'react-i18next';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  const { t } = useTranslation('common');

  const listIcon = <FontAwesomeIcon icon={faAngleRight} />;

  return (
    <footer className={'footer'}>
      <div className={'inner'}>
        <div className={'mv-8'}>
          {listIcon}
          <a href='https://www.facebook.com/osta.ee' className={'ml-2 smaller'}>{t('osta_in_facebook')}</a>
        </div>
        <div className={'flex-nowrap group-items'}>
          <a href='https://balticclassifieds.com' target='_blank' rel='nofollow noreferrer'>
            <img src='https://www.osta.ee/assets/gfx/bcg_logo.svg' alt='Baltic Classifieds Group' height={'30px'} className={'mr-4 mb--1'} />
          </a>

          <div className={'flex-wrap flex-center gap-v0'}>
            <a href='https://www.osta.ee' target='_blank' rel='noreferrer'>Osta.ee</a>
            <a href='https://www.kv.ee' target='_blank' rel='nofollow noreferrer'>KV.ee</a>
            <a href='https://www.auto24.ee' target='_blank' rel='nofollow noreferrer'>auto24.ee</a>
            <a href='https://getapro.ee/' target='_blank' rel='nofollow noreferrer'>GetaPro.ee</a>
            <a href='https://www.kuldnebors.ee' target='_blank' rel='nofollow noreferrer'>KuldneBörs.ee</a>
            <a href='https://www.city24.ee' target='_blank' rel='nofollow noreferrer'>City24.ee</a>
            <a href='https://getapro.lv/' target='_blank' rel='nofollow noreferrer'>GetaPro.lv</a>
            <a href='https://www.city24.lv' target='_blank' rel='nofollow noreferrer'>City24.lv</a>
            <a href='https://www.aruodas.lt' target='_blank' rel='nofollow noreferrer'>Aruodas.lt</a>
            <a href='https://www.skelbiu.lt' target='_blank' rel='nofollow noreferrer'>Skelbiu.lt</a>
            <a href='https://www.autoplius.lt' target='_blank' rel='nofollow noreferrer'>Autoplius.lt</a>
            <a href='https://www.cvbankas.lt' target='_blank' rel='nofollow noreferrer'>CVbankas.lt</a>
            <a href='https://www.kainos.lt' target='_blank' rel='nofollow noreferrer'>Kainos.lt</a>
            <a href='https://www.paslaugos.lt' target='_blank' rel='nofollow noreferrer'>Paslaugos.lt</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;