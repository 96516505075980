import Url from '../helpers/Url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPersonCarryBox, faTruckFast } from '@fortawesome/pro-regular-svg-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Currency from '../helpers/Currency';
import GetPrices, { cheapestPrice } from '../helpers/GetPrices';


const MainMenu = ({ home = false }: { home?: boolean }) => {
  const { t } = useTranslation('common');
  const [cheapest, setCheapest] = useState(cheapestPrice);

  if (!cheapest) {
    GetPrices(true).then(res => {
      setCheapest(res);
    });
  }

  return (<>
    <div className={'clear-fix mt-5 hide-on-mobile'} />
    <div className={'flex-row mb-8 mt-3'}>
      <div className={'col'}>
        <Url href={'/send'} className={'btn-menu' + (home ? ' active' : '')}>
          <>
            <FontAwesomeIcon icon={faPersonCarryBox} className={'mr-2'} />
            {t('menu.send-package')}
            <span className={'right text-primary'}>
              {t('menu.send-package-teaser')}
              {Currency(cheapest)}
            </span>
          </>
        </Url>
      </div>
      <div className={'col'}>
        <Url href={'/follow'} className={'btn-menu'}>
          <>
            <FontAwesomeIcon icon={faTruckFast} className={'mr-2'} />
            {t('menu.track-packages')}
          </>
        </Url>
      </div>
      <div className={'col'}>
        <Url href={'/map'} className={'btn-menu'}>
          <>
            <FontAwesomeIcon icon={faLocationDot} className={'mr-2'} />
            {t('menu.find-on-map')}
          </>
        </Url>
      </div>
    </div>
  </>);
};
export default MainMenu;