import Url from '../helpers/Url';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Currency from '../helpers/Currency';
import { priceData } from '../interfaces/PriceData';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Provider from '../pages/send/Provider';
import IsMobile from '../helpers/IsMobile';


const Pack = ({ size, prices, active = false }: { size: string, prices: priceData[], active?: boolean }) => {
  const { t } = useTranslation('common');
  const { provider } = useParams();

  const isMobile = IsMobile();

  let minPrice = 1000;
  let minSize = 1000;
  let maxSize = 0;

  for (const [, details] of Object.entries(prices)) {
    if (minPrice > details['price']) {
      minPrice = details['price'];
    }
    if (minSize > details['height']) {
      minSize = details['height'];
    }
    if (maxSize < details['height']) {
      maxSize = details['height'];
    }
  }


  let packContent = (<>
    <div className={'chosen'}><FontAwesomeIcon icon={faCheck} className={'mr-1'} /> {t('chosen')}</div>
    <img src={'/img/pack/' + size + (active ? '_active' : '') + '.svg'} alt={size.toUpperCase()} className={(active ? 'hide-on-mobile' : '')} />
    <div className={'details'}>
      <span className={'title'}>{size.toUpperCase()}</span>
      <span className={'price'}>{t('price_from')}{Currency(minPrice)}</span>
    </div>
    <div className={'size'}>
      {t('box.size')} <strong>{minSize}{maxSize > minSize ? ' - ' + maxSize : ''}{t('default_size_unit')}</strong>
    </div>
  </>);

  if (active && isMobile) {
    return (
      <div className={'btn-pack active ' + size}>
        {packContent}
        <Provider size={size} prices={prices} />
      </div>
    );
  }


  // @ts-ignore
  return (<Url href={'/send/' + size + (provider && prices[provider] ? '/' + provider : '')} className={'btn-pack'}>
      {packContent}
    </Url>
  );
};
export default Pack;